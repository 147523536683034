import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
// import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./shared/components/layouts/public/public-layout.module").then(m => m.PublicLayoutModule),
  },
  // {
  //   path: "app",
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import("./shared/components/layouts/app-layout/app-layout.module").then(m => m.AppLayoutModule),
  // },
  // {
  //   path: "auth",
  //   loadChildren: () => import("./shared/components/layouts/auth-layout/auth-layout.module").then(m => m.AuthLayoutModule),
  // },
  { path: '**', redirectTo: '' }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
